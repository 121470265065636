.arrowDownMenuIcon {
  margin-left: 5px !important;
  font-size: 20px !important;
  width: unset !important;
  height: unset !important;
}
.header {
  z-index: 9999 !important;
}

.header > button:last-child {
  position: absolute;
  text-align: left;
}
.header > div:first-child {
  margin-left: 2.8rem !important;
}
