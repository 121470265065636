.gridBox {
  height: calc(100vh - 12rem);
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.card {
  width: 20rem;
  cursor: pointer;
  height: 7rem;
}
.dimCard {
  width: 20rem;
  cursor: not-allowed;
  opacity: 0.5;
  height: 7rem;
}
